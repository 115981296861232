<template>
  <div class="homepage-3">
    <ScrollupSection />
    <div class="main overflow-hidden">
      <HeaderSection />
      <BreadcrumbSection :titulo="titulo" :descripcion="descripcion" />
      <section class="section service-area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <horizontal-stepper
                :steps="demoSteps"
                @completed-step="completeStep"
                @active-step="isStepActive"
                @stepper-finished="alert"
                class="m-5"
                locale="es"
              > 
              </horizontal-stepper>
            </div>
          </div>
        </div>
      </section>
      <FooterSection />
      <ModalSearchSection />
      <ModalRegistro />
      <ModalMenuSection />
      <ModalTerms/>
    </div>
  </div>
</template>

<script>
import {analytics, auth} from "../../../firebase";
import { mapActions, mapMutations } from "vuex";

import ScrollupSection from "../../Scrollup/Scrollup";
import HeaderSection from "../../Header/HeaderOne";
import BreadcrumbSection from "../../Breadcrumb/Services";
import FooterSection from "../../Footer/FooterOne";

import ModalSearchSection from "../../Modal/ModalSearch/ModalSearch";
import ModalRegistro from "../../Modal/ModalSearch/ModalRegistro";
import ModalMenuSection from "../../Modal/ModalMenu/ModalMenu";
import ModalTerms from '../../Modal/ModalTerms/ModalTerms';

import HorizontalStepper from "vue-stepper";
import GeneralesRepresentante from "../../../components/forms/Remuneraciones/GeneralesRepresentante";
import Generals from "../../../components/forms/Remuneraciones/Generals";
import Pruebas from "../../../components/forms/Remuneraciones/Pruebas";
import Complement from "../../../components/forms/Remuneraciones/Complement";
export default {
  name: "agentesMunicipales",
  metaInfo: {
    title:
      "Electoral Tech & Legal | Agentes Municipales",
    description:
      "Crea y genera de manera simple tu formato de juicio cuidadano",
  },
  components: {
    ScrollupSection,
    HeaderSection,
    BreadcrumbSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ModalRegistro,
    HorizontalStepper,
    ModalTerms
  },
  mounted() {
    window.$("#modalTerminos").modal("show");
    analytics.logEvent("formatoComenzado", { name: "Agentes Municipales" });
    this.setTipoFormato(0);
  },
  data() {
    return {
      titulo:"Agentes Municipales",
      tituloFormato: "Agentes Municipales",
      tituloArchivo: "agentesMunicipales.pdf",
      descripcion: "¿Eres autoridad municipal auxiliar de elección popular ? ¿Sabías que el juicio electoral ciudadano protege tu derecho político-electoral en la vertiente de desempeñar el cargo y recibir el pago correspondiente? Elabora tu propia demanda de manera eficaz y eficiente.",
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/generarJDCAgentesMuniciapales",
      urlLocal:
        "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/generarJDCAgentesMuniciapales",
      demoSteps: [
        {
          icon: "person",
          name: "first",
          title: "Datos del representante",
          // subtitle: "Subtitle sample",
          component: GeneralesRepresentante, 
        },
        {
          icon: "person",
          name: "sencond",
          title: "Datos del accionante",
          // subtitle: "Subtitle sample",
          component: Generals,
        },
        {
          icon: "announcement",
          name: "fourth",
          title: "Actos denunciados",
          // subtitle: "Subtitle sample",
          component: Complement,
        },
        {
          icon: "speaker_notes",
          name: "seventh",
          title: "Pruebas",
          // subtitle: "Subtitle sample",
          component: Pruebas,
          completed: false,
        },
      ],
    };
  },
  computed: {
    url() {
      return process.env.NODE_ENV == "development"
        ? this.urlLocal
        : this.urlProd;
    },
  },
  methods: {
    ...mapMutations('generals', ['setTipoFormato']),
    ...mapActions('contenido', ['guardarFormato']),
    completeStep(payload) {
      this.demoSteps.forEach((step) => {
        if (step.name === payload.name) {
          step.completed = true;
        }
      });
    },
    errorMessage(error) {
      this.$swal({
        icon: "error",
        title: error,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: true,
      });
    },
    // Executed when @active-step event is triggered
    isStepActive(payload) {
      this.demoSteps.forEach((step) => {
        if (step.name === payload.name) {
          if (step.completed === true) {
            step.completed = false;
          }
        }
      });
    },
    async alert() {
      // window.$("#ModalCaptcha").modal("show");}
      if ( auth.currentUser != null ) {
        try{
          this.$swal({
            icon: "info",
            title: "Guardando comentario",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            timerProgressBar: true,
            showCancelButton: true,
            didOpen: () => this.$swal.showLoading(),
          });
          await this.guardarFormato({nombreFormato: this.tituloFormato, tipoFormato: 5});
          this.$swal({
            icon: "success",
            title: "Formato De Agentes Municipales Generado",
            text: "Para descargar el formato, debes ir a tu perfil, revisar la información de pago, y subir tu comprobante de pago",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              console.log('confirmo')
              this.$router.push({ name: "perfil" });
            } else if (result.isDenied) {
              console.log('no confirmo')
            }
          });
        }catch (e) {
          console.warn(e);
          this.errorMessage('Revise su conexión a internet y pruebe más tarde');
        }
      } else {
        const result = await this.$swal({
          icon: "info",
          title: "Registrate o Inicia sesión!",
          text: "Crea tu cuenta o inicia sesión para descargar este formato en tu perfil",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCancelButton: true,
          confirmButtonText: 'Crear cuenta ahora',
        });
        if (result.isConfirmed) {
          window.$("#registro").modal('show');
        } else if (result.isDenied) {
          this.$router.push({ name: "inicio" });
          console.log('no confirmo')
        }
      }
    },
  },
};
</script>
<style>
.stepper-box .content {
  overflow: visible !important;
}
.v-select {
  /* height: calc(1.5em + 0.75rem + -2px); */
  box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
  border: none;
}

.vs--searchable .vs__dropdown-toggle {
  border: none;
}
</style>
