<template>
  <div style="margin: 10px">
    <form>
      <div class="row">
        <div class="mb-3 col-lg-4 col-xs-12">
          <label for="TipoDocumento" class="form-label">
            Tipo documento*
          </label>
          <v-select
            :options="tipoDocData"
            v-model="$v.form.tipoDoc.$model"
            aria-label="listado de documentos"
            placeholder="Selecciona"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="!form.tipoDoc"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <slot name="no-options">No se encontraron coincidencias.</slot>
          </v-select>
        </div>

        <div class="mb-3 col-lg-4 col-xs-12">

          <div class="form-group">
            <label for="numDocumento" class="form-label" v-if="form.tipoDoc.label == null"> Numero Documento* </label>
            <label for="numDocumento" class="form-label" v-if="form.tipoDoc.label == 'Credencial para votar'"> Clave de elector* </label>
            <label for="numDocumento" class="form-label" v-if="form.tipoDoc.label == 'Pasaporte'"> Número de pasaporte* </label>
            <label for="numDocumento" class="form-label" v-if="form.tipoDoc.label == 'Cédula profesional'"> Registro cédula* </label>
            <input
              type="text"
              id="numDocumento"
              class="form-control"
              :class="{ error: $v.form.numDoc.$error }"
              v-model.trim="$v.form.numDoc.$model"
              aria-label="Número de documento"
            />
            <!-- <small>Campo númerico de minimo 7 dígitos</small> -->
          </div>
        </div>

        <!-- 
        <div class="mb-3 col-lg-4 col-xs-12">
          <label
            for="representantePart"
            class="form-label"
            aria-label="representantePart"
          >
            Representante partidista propietario *</label
          >
          <input
            type="text"
            id="representantePart"
            class="form-control"
            :class="{ error: $v.form.representantePart.$error }"
            v-model.trim="$v.form.representantePart.$model"
            aria-label="representantePart"
          />
        </div> 
        -->

      </div>

      <div class="row">
        <div class="mb-3 col-lg-4 col-xs-12">
          <label for="nombres" class="form-label" aria-label="Nombres">
            Nombres *</label
          >
          <input
            type="text"
            id="nombres"
            class="form-control"
            :class="{ error: $v.form.nombres.$error }"
            v-model.trim="$v.form.nombres.$model"
            aria-label="nombres"
          />
        </div>
        <div class="mb-3 col-lg-4 col-xs-12">
          <label for="appPat" class="form-label" aria-label="Apellido paterno">
            Apellido Paterno *
          </label>
          <input
            type="text"
            id="appPat"
            class="form-control"
            :class="{ error: $v.form.appPat.$error }"
            v-model.trim="$v.form.appPat.$model"
            aria-label="Apellido paterno"
          />
        </div>
        <div class="mb-3 col-lg-4 col-xs-12">
          <label for="appMat" class="form-label" aria-label="Apellido materno">
            Apellido Materno *
          </label>
          <input
            type="text"
            id="appMat"
            class="form-control"
            :class="{ error: $v.form.appMat.$error }"
            v-model.trim="$v.form.appMat.$model"
            aria-label="Apellido materno"
          />
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-lg-4 col-xs-12">
          <label for="estado" class="form-label"> Estado *</label>
          <v-select
            id="estado"
            :options="estados"
            v-model="form.estado"
            aria-label="Estados"
            placeholder="Selecciona"
            label="estado"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="!form.estado"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <slot name="no-options">No se encontraron coincidencias.</slot>
          </v-select>
        </div>
        <div class="mb-3 col-lg-4 col-xs-12">
          <label for="ciudad" class="form-label"> Ciudad *</label>
          <v-select
            id="ciudad"
            :options="ciudades"
            v-model="form.ciudad"
            aria-label="Ciudades"
            placeholder="Selecciona"
            label="mun"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="!form.ciudad"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <slot name="no-options">No se encontraron coincidencias.</slot>
          </v-select>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-lg-4 col-xs-12">
          <label
            for="correoElectronico"
            class="form-label"
            aria-label="Correo electrónico"
          >
            Correo electrónico *
          </label>
          <input
            type="text"
            id="correoElectronico"
            class="form-control"
            :class="{ error: $v.form.correo.$error }"
            v-model.trim="$v.form.correo.$model"
            aria-label="Correo electrónico"
          />
        </div>
        <div class="mb-3 col-lg-4 col-xs-12">
          <label for="telefono" class="form-label" aria-label="teléfono">
            Teléfono *
          </label>
          <input
            type="text"
            id="telefono"
            class="form-control"
            :class="{ error: $v.form.telefono.$error }"
            v-model.trim="$v.form.telefono.$model"
            aria-label="teléfono"
          />
        </div>
        <div class="mb-3 col-lg-4 col-xs-12">
          <label
            for="direccion"
            class="form-label"
            aria-label="dirección de notificaciones"
          >
            Dirección de notificaciones del accionante *
          </label>
          <input
            type="text"
            id="direccion"
            class="form-control"
            :class="{ error: $v.form.direccion.$error }"
            v-model.trim="$v.form.direccion.$model"
            aria-label="dirección de notificaciones"
          />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import { required, email, numeric } from "vuelidate/lib/validators";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  props: ["clickedNext", "currentStep"],
  name: "GeneralForm",
  mounted() {
    // this.getStates();
    /* console.log("mounted", this.$v.$invalid); */
    this.setMunicipios([]);
    if (!this.$v.$invalid) {
      this.$emit("can-continue", { value: true });
    } else {
      // this.verTerminos();
      this.$emit("can-continue", { value: false });
    }
  },
  activated: function () {
    /* console.log("activate"); */
    if (!this.$v.form.$invalid) {
      // console.log("activate can");
      this.$emit("can-continue", { value: true });
    } else {
      // console.log("activate cant");
      this.$emit("can-continue", { value: false });
    }
  },
  data() {
    return {
      form: {
        tipoDoc: {
          id: null,
          label: null
        },
        estado: null,
        ciudad: null,
        representantePart: "",
        nombres: "",
        appPat: "",
        appMat: "",
        correo: "",
        telefono: "",
        direccion: "",
        numDoc: "",
      },
    };
  },
  validations() {
    return {
      form: {
        tipoDoc: { required },
        estado: { required },
        ciudad: { required },
        //representantePart: {
        //   required,
        //},
        nombres: {
          required,
        },
        appPat: {
          required,
        },
        appMat: {
          required,
        },
        correo: {
          required,
          email,
        },
        telefono: {
          numeric,
          required,
        },
        direccion: {
          required,
        },
        numDoc: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapState("generals", [
      "tipoDocData",
      "generosData",
      "ciudades",
      "estados",
    ]),
    estadoSeleccionado() {
      return this.form.estado;
    },
  },
  methods: {
    ...mapMutations("contenido", ["setFormatoDatos"]),
    ...mapMutations("generals", ["setMunicipios"]),
    ...mapActions({
      getStates: "generals/getStates",
      getMunicipios: "generals/getMunicipios",
    }),
  },
  watch: {
    $v: {
      handler: function (val) {
        // console.log("es invalido cambio.... ", val.$invalid);
        if (!val.$invalid) {
          this.$emit("can-continue", { value: true });
          this.setFormatoDatos(this.form);
        } else {
          this.$emit("can-continue", { value: false });
        }
      },
      deep: true,
    },
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
        // this.setFormatoDatos(this.form)
      }
    },
    estadoSeleccionado: {
      handler: function (val) {
        if (val) {
          this.form.ciudad = null;
          console.log(val.id);
          this.getMunicipios(val.id - 1);
        }
      },
    },
  },
};
</script>
<style>
.form-control {
  height: calc(1.5em + 0.75rem + 2px);
}
.vs__dropdown-toggle {
  border: 1px solid #eee;
  height: calc(1.5em + 0.75rem + 2px);
}
.v-select {
  height: calc(1.5em + 0.75rem + 2px);
  box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
}
.error {
  border: 1px solid #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>